























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({
  components: {
    VueJsonToCsv,
  },
})
export default class DotsMenu extends Vue {
  @Prop() createPDF!: Function;
  @Prop({ default: true }) attach!: Boolean;
  @Prop() saveXLS!: Function;
  @Prop() openDialog!: Function;
  @Prop() submitEditReport!: Function;
  @Prop() rowsCSV!: Getter<any, any>;
  @Prop() headersCSV!: Getter<any, any>;
  @Prop() validateEdit!: Getter<any, any>;
  @Prop() fileName!: String;
  @Prop() type!: String;
  @Prop() currentObjForSaving;
  @Prop() setItem;
  @Prop() copy: Function | undefined;
  @Prop() isCompare?: Boolean;

  color: String = BASE_BLACK_COLOR;

  get xlsLinkText() {
    return this.isCompare ? 'XLS Export alle Nebenbedingungen' : 'Download XLS';
  }
  get isSetItem() {
    return this.$router.currentRoute.name !== 'report' ? this.setItem : null;
  }
}
