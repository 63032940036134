


























































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { groupBy } from '@/utils/helpers';
import { BASE_BLACK_COLOR, HEADLINES, OFFERS } from '@/utils/constants';

import CreateNewReport from '@/components/fmh_components/common/CreateNewReport/CreateNewReport.vue';
import DotsMenu from '@/components/fmh_components/common/DotsMenu/DotsMenu.vue';

@Component({
  components: { CreateNewReport, DotsMenu },
})
export default class ReportsIterator extends Vue {
  @Prop() allReports!: Array<Object>;
  @Prop() tags!: Array<String>;
  @Prop() type!: String;
  @Prop() selected!: Array<String>;
  @Prop() currentObjForSaving!: Object;
  @Prop() csvFileName: String | undefined;
  @Prop() submitEditReport!: Function;
  @Prop() goWizard!: Function;
  @Prop() createPDF!: Function;
  @Prop() openDialog!: Function;
  @Prop() copy!: Function;
  @Prop() setItem!: Function;
  @Prop() goSingleReport!: Function;
  @Prop() saveXLS: Function | undefined;
  @Prop() rowsCSV: Array<Object> | undefined;
  @Prop() headersCSV: Array<Object> | undefined;

  headlines: Object = HEADLINES;
  color = BASE_BLACK_COLOR;
  copyGroups = {
    latest: [],
    autoUpdate: [],
    other: [],
  };
  groups = {
    latest: [],
    autoUpdate: [],
    other: [],
  };
  page = {
    latest: 1,
    autoUpdate: 1,
    other: 1,
  };
  itemsPerPage = {
    latest: 3,
    autoUpdate: 7,
    other: 7,
  };
  offers = OFFERS;

  @Watch('allReports', { immediate: true }) setGroupsBy() {
    this.groups = this.setGroups(this.allReports, 'category');
    this.copyGroups = this.setGroups(this.allReports, 'category');
    this.allReports.forEach((item: any) => {
      if (item?.tags) {
        for (let tag of item?.tags) {
          if (!this.tags.includes(tag)) {
            this.tags.push(tag);
          }
        }
      }
    });
  }

  @Watch('selected') filterReports() {
    if (this.selected.length) {
      const o = {
        latest: [],
        autoUpdate: [],
        other: [],
      };
      Object.keys(this.copyGroups).forEach((key) => {
        const result = this.copyGroups[key].filter((report) => {
          return report.tags.some((tag) => this.selected.includes(tag));
        });
        Object.assign(o, { [key]: result });
      });
      this.groups = o;
    } else {
      this.groups = this.copyGroups;
    }
  }

  get validateEdit() {
    return !!(
      (this.currentObjForSaving as any)?.meta &&
      (this.currentObjForSaving as any).meta?.editable
    );
  }
  setGroups(arr, property) {
    return groupBy(arr, property);
  }
  nextPage(group) {
    if (this.page[group] + 1 <= this.numberOfPages(group))
      this.page[group] += 1;
  }
  formerPage(group) {
    if (this.page[group] - 1 >= 1) this.page[group] -= 1;
  }
  numberOfPages(group) {
    return Math.ceil(this.groups[group].length / this.itemsPerPage[group]);
  }
  validate(item) {
    return item?.rows && item?.rows.length > 0;
  }
}
